.chat-gpt-response-container{
    display: flex;
    width: 100%;
  }
  
  .chat-gpt-response{
    flex: 1;
    padding: 4px 6px 0 0;
  }

  .chat-gpt-html-response{
    flex: 1;
    padding: 4px 6px 0 0;
    text-align: left;
  }

  .chat-gpt-html-response h1 {
    font-size: 19px;
  }

  .chat-gpt-html-response h2{
    font-size: 18px;
  }

  .chat-gpt-html-response h3, 
  .chat-gpt-html-response h4,
  .chat-gpt-html-response h5,
  .chat-gpt-html-response h6{
    font-size: 16px;
  }

  .single-line-chat-gpt-response{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    padding: 4px 6px 0 0;
  }

  .bq-response-header{
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .bq-response-lable{
    margin: 0;
  }
  
  .bq-response-container{
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
    background-color: rgb(75, 75, 75);
    gap: 6px;
    width: calc(100% - 1rem);
    color: rgb(210, 210, 210);
  }

  .bq-response{
    flex: 1;
    padding: 2px 6px 2px 0;
  }

  .response-wrapper{
    max-width: 80%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
  
  .response-item{
    border-radius: 8px;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 10px 6px 1rem;
    background-color: rgb(231, 231, 231);
  }
  
  .reaction-container{
    width: 100%;
    display: flex;
    height: 20px;
    justify-content: flex-end;
  }

  .copy-button-container{
    min-width: 14px;
  }

  .response-table-container {
    margin: 4px 0;
    border-radius: 4px;
    background-color: rgb(75, 75, 75);
    width: calc(100% - 1rem);
    color: rgb(210, 210, 210);
  }

  div.response-table-row{
    background-color: rgba(0, 0, 0, 0);
    border-bottom-color: rgb(100, 100, 100) !important;
  }

  div.rs-table{
    border-radius: 4px;
  }

  div.rs-table-cell:not(.rs-table-cell-last) {
    border-right: 1px solid rgb(100, 100, 100) !important;
  }

  .response-loader-container{
    display: flex;
    align-items: flex-end;
    gap: 6px;
  }

  .timer-container{
    display: flex;
    gap: 2px;
  }

  .response-timer-icon{
    height: 12px;
    margin-top: 1px;
  }

  .response-timer-text{
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 2px;
    margin-right: 4px;
  }

  .timer-divider{
    height: 12px;
    margin: 1px 4px 0 0;
    width: 1px;
    background-color: gray;
  }