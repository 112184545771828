.ModalContainer{
    width: auto !important;
}

.ModalBody{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 1rem 2rem !important;
}

.LoginHeading{
    font-weight: 900 !important;
    font-size: 32px;
    margin-bottom: 1rem;
}

.PasswordLabelContainer{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
}

.PasswordIcon{
    margin-bottom: 2px;
}

.ButtonContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.SubmitButton{
    margin-top: 6px;
    min-width: 100px;
    color: white;
}