/* src/App.css */

.App {
  text-align: center;
}

.gpt-page-container{
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
}

.header{
  height: 56px;
  padding: 0 12px 0 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: white;
}

.analytix-text{
  margin: 0 1rem;
}

.announcement-bar{
  height: 40px;
  box-sizing: border-box;
  background-image: url("./../public/announcement-bg.png");
  display: flex;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: white;
  position: sticky;
  top: 0;
}

.page-body{
  display: flex;
  min-height: calc(100vh - 96px);
}

.page-content{
  width: calc(100vw - 60px);
  padding-top: 1rem;
  box-sizing: border-box;
  transition: width 300ms;
  min-height: calc(100vh - 96px)
}

.page-content-expanded{
  width: calc(100vw - 300px);
  transition: width 300ms;
  padding-top: 1rem;
  box-sizing: border-box;
  min-height: calc(100vh - 96px);
  border-left: 1px solid lightgray;
}

.initial-display{
  height: calc(100vh - 232px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
}

h1 {
  color: #333;
  margin: 0;
  padding: 0;
}

pre {
  text-align: left;
  border-radius: 4px;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.chat-container{
  width: 100%;
  box-sizing: border-box;
  min-height: calc(100vh - 248px);
}

.chat-container-expanded{
  padding: 0 5vw;
}

.chat-container-shrinked{
  padding: 0 10vw;
}

.question-container{
  width: 100%;
  box-sizing: border-box;
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: flex-start;
}

.programmer-icon{
  height: 24px;
  width: 24px;
}

.response-container{
  width: 100%;
  box-sizing: border-box;
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.gpt-icon-container{
  margin-top: 4px;
}

.gpt-icon{
  height: 28px;
  width: 28px;
}

.chat-item{
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.question-text{
  padding: 6px 1rem;
  border-radius: 8px;
  inline-size: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  text-align: left;
  background-color: rgb(231, 231, 231);
}

.current-time-container{
  display: inline block;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  border-radius: 2px;
  padding: 2px 4px;
}

.chat-input-container{
  padding: 1rem 10vw 1.5rem 10vw;
  background-color: white;
  position: sticky;
  z-index: 2;
  bottom: 0;
}

.input-area-container{
  background-color: rgb(236, 236, 236);
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  box-sizing: border-box;
}

.input-area{
  flex: 1;
  padding: 6px;
  border-radius: 8px;
  height: auto;
  max-height: 200px;
  min-height: 32px;
  background-color: transparent;
  outline: none;
  resize: none;
  border: none;
}

.run-button{
  background-color: rgb(75, 75, 75);
  color: white;
  border: none;
  height: 40px;
  width: 60px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.run-button:hover{
  background-color: rgb(48, 48, 48);
}

.run-button-disabled{
  background-color: rgb(144, 144, 144);
  color: white;
  border: none;
  height: 40px;
  width: 60px;
  cursor: progress;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-image{
  height: 16px;
  width: 16px;
  margin: auto;
}

@media screen and (max-width: 720px) {
  .chat-container {
    padding: 0 1rem;
  }

  .response-wrapper, .chat-item{
    max-width: 70%;
  }
}

.rs-modal-wrapper{
  display: flex;
  align-items: center;
}

div.login-modal-backdrop{
  background-color: rgba(0, 0, 0, 0.64);
}