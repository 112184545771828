.side-nav{
    transition: width 300ms;
    max-height: calc(100vh - 96px);
    position: sticky;
    top: 40px;
}
.side-nav-expanded{
    width: 300px;
    transition: width 300ms;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.side-nav-expanded::-webkit-scrollbar {
    display: none;
}  
.side-nav-collapsed{
    width: 50px;
    transition: width 300ms;
}
.header-container{
    padding: 0 0 8px 0;
    background-color: white;
    width: 100%;
    position: sticky;
    top: 0;
}
.button-container{
    display: flex;
    box-sizing: border-box;
    padding: 12px;
}
.button-container-collapsed
{
    width: 100%;
    align-items: center;
}
.button-container-expanded
{
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gray;
}
.thread-history-header{
    font-size: 18px;
    font-weight: bolder;
    color: gray;
}
.history-icon{
    height: 24px;
    width: 24px;
    background-size: 24px 24px;
    background-image: url('./../../../public/collapse-sidebar.png');
    transition: scale 300ms;
}
.history-icon:hover{
    transform: scale(1.05);
    transition: scale 300ms;
    background-image: url('./../../../public//collapse-sidebar-hover.png');
}