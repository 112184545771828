.ReactionCardContainer{
    width: 200px;
    height: 100%;
    margin-right: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
}

.ReactionImage{
    height: 16px;
    cursor: pointer;
}