.thread-history-container{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
}
.thread-day-group{
    width: 100%;
}
.day-container{
    display: flex;
    box-sizing: border-box;
    padding: 4px 8px;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
}
.day-text{
    text-align: left;
    letter-spacing: 1px;
    font-size: 12px;
    color: gray;
    font-weight: bold;
}

.thread-item{
    height: 36px;
    border-radius: 4px;
    display: flex;
    transition: background-color 200ms ease;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding: 8px;
    box-sizing: border-box;
}
.chat-icon{
    margin-top: 6px;
    height: 16px;
    width: 16px;
}
.thread-item:hover{
    background-color: rgb(219, 219, 219);
}
.thread-title{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}